export default {
  data: () => ({}),

  methods: {
    parseDiskSize(newSize) {
      let sizeString = newSize;
      if (newSize === null) {
        return NaN;
      }
      if (typeof newSize !== "string") {
        sizeString = sizeString.toString();
      }
      sizeString = sizeString.toUpperCase();
      if (sizeString) {
        let stringTokens = sizeString.match(/(\d+(\.\d+)?)(\w+)?/);
        if (stringTokens) {
          let numBytes = NaN;
          let numBytesString = parseFloat(stringTokens[1]);
          if (!isNaN(numBytesString)) {
            if (stringTokens[3] === "TB") {
              numBytes = numBytesString * 1000000000000;
            } else if (stringTokens[3] === "GB") {
              numBytes = numBytesString * 1000000000;
            } else if (stringTokens[3] === "MB") {
              numBytes = numBytesString * 1000000;
            } else if (stringTokens[3] === "KB") {
              // kB
              numBytes = numBytesString * 1000;
            } else {
              numBytes = numBytesString;
            }
            return Math.ceil(numBytes);
          }
        }
      }
      return NaN;
    },
    prettifyDiskSize(numBytes) {
      let sizeString = "-";
      if (Math.floor(numBytes / 1000000000000) > 0) {
        sizeString = `${Math.floor((numBytes * 100) / 1000000000000) / 100}TB`;
      } else if (Math.floor(numBytes / 1000000000) > 0) {
        sizeString = `${Math.floor((numBytes * 100) / 1000000000) / 100}GB`;
      } else if (Math.floor(numBytes / 1000000) > 0) {
        sizeString = `${Math.floor((numBytes * 100) / 1000000) / 100}MB`;
      } else if (Math.floor(numBytes / 1000) > 0) {
        sizeString = `${Math.floor(numBytes / 1000)}kB`;
      } else {
        sizeString = `${Math.floor(numBytes)}B`;
      }
      return sizeString;
    },
    calculateColourDiskSpaceUsed(total, max) {
      if (total && max) {
        let pc = this.calculatePcDiskSpaceUsed(total, max);
        if (pc < 80) {
          return "green";
        } else if (pc < 90) {
          return "orange";
        }
        return "red";
      }
      return "grey";
    },
    calculatePcDiskSpaceUsed(total, max) {
      if (total && max) {
        return Math.ceil((100 * total) / max);
      }
      return 100;
    },
  },
};
