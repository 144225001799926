<template>
  <span>{{ organisationLevel }}</span>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";

export default {
  name: "OrganisationLevelFieldRO",
  props: {
    levelValue: {
      type: Number,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    organisationLevel() {
      let level = APP_CONSTANTS.ORGANISATION_LEVELS.find((item) => {
        return item.value === this.levelValue;
      });
      return this.$t(`organisations.levels.${level.name}`);
    },
  },
};
</script>
