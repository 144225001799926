<template>
  <v-skeleton-loader :loading="status.loading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="organisations-show">
      <template v-if="objectId">
        <v-container class="pa-0">
          <v-alert
            v-if="organisation.id !== loggedInOrganisationId"
            type="info"
            outlined
            class="my-8"
          >
            <div class="mb-4 text-center">
              {{ $t("organisations.dataPrivacyWarning") }}
            </div>
            <template v-if="hasImpersonationsFromOrganisation(organisation.id)">
              <div class="mb-4 text-center">
                {{ $t("organisations.dataPrivacyWarningHasImpersonation") }}
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  v-bind="propsButtonAction"
                  @click.prevent="clickImpersonate(organisation.id)"
                >
                  <v-icon>$impersonation</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                {{ $t("organisations.dataPrivacyWarningHasImpersonation") }}
              </div>
            </template>
          </v-alert>

          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-avatar color="transparent" size="8em" rounded="0">
                <v-img :src="logoUrl(organisation.id)" contain></v-img>
              </v-avatar>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.name") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ organisation.name }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">
                {{ $t("organisations.levels.label") }}:
              </div>
            </v-col>
            <v-col cols="12" md="10">
              <div>
                <OrganisationLevelFieldRO
                  :levelValue="organisation.level"
                ></OrganisationLevelFieldRO>
              </div>
            </v-col>
          </v-row>

          <template v-if="organisation.id === loggedInOrganisationId">
            <v-row>
              <v-col cols="12" md="2">
                <div class="label-show">{{ $t("common.address") }}:</div>
              </v-col>
              <v-col cols="12" md="10">
                <div>{{ organisation.address }}</div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">
                <div class="label-show">{{ $t("common.city") }}:</div>
              </v-col>
              <v-col cols="12" md="10">
                <div>{{ organisation.city }}</div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">
                <div class="label-show">{{ $t("common.postalCode") }}:</div>
              </v-col>
              <v-col cols="12" md="10">
                <div>{{ organisation.postalCode }}</div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">
                <div class="label-show">
                  {{ $t("common.countryIso3166_a2") }}:
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div>
                  <CountryPickerFieldRO
                    :countryValue="organisation.countryIso3166_a2"
                  ></CountryPickerFieldRO>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">
                <div class="label-show">
                  {{ $t("common.languageIso639_1") }}:
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div>
                  <LanguagePickerFieldRO
                    :languageValue="organisation.languageIso639_1"
                  ></LanguagePickerFieldRO>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">
                <div class="label-show">{{ $t("common.description") }}:</div>
              </v-col>
              <v-col cols="12" md="10">
                <div>{{ organisation.description }}</div>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">
                {{ $t("organisations.totalFileCount") }}:
              </div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ organisation.totalFileCount }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">
                {{ $t("organisations.totalFileSize") }}:
              </div>
            </v-col>
            <v-col cols="12" md="10" class="d-flex align-center">
              <span>{{ prettifyDiskSize(organisation.totalFileSize) }}</span>
              <span v-if="organisation.maxFileSize">
                &nbsp;/&nbsp;{{
                  prettifyDiskSize(organisation.maxFileSize)
                }}</span
              >
              <v-progress-circular
                :rotate="90"
                :size="32"
                :color="
                  calculateColourDiskSpaceUsed(
                    organisation.totalFileSize,
                    organisation.maxFileSize
                  )
                "
                :value="
                  calculatePcDiskSpaceUsed(
                    organisation.totalFileSize,
                    organisation.maxFileSize
                  )
                "
                class="ml-2"
              >
                <span style="font-size: 60%">{{
                  calculatePcDiskSpaceUsed(
                    organisation.totalFileSize,
                    organisation.maxFileSize
                  )
                }}</span>
              </v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-if="isReseller(organisation)">
            <v-col cols="12" md="2">
              <div class="label-show">
                {{ $t("organisations.resellerTotalFileSize") }}:
              </div>
            </v-col>
            <v-col cols="12" md="10" class="d-flex align-center">
              <span>{{
                prettifyDiskSize(organisation.assignedResellerFileSize)
              }}</span>
              <span>
                &nbsp;({{
                  prettifyDiskSize(organisation.totalResellerFileSize)
                }})</span
              >
              <span>
                &nbsp;/&nbsp;{{
                  prettifyDiskSize(organisation.maxResellerFileSize)
                }}</span
              >
              <v-progress-circular
                :rotate="90"
                :size="32"
                :color="
                  calculateColourDiskSpaceUsed(
                    organisation.assignedResellerFileSize,
                    organisation.maxResellerFileSize
                  )
                "
                :value="
                  calculatePcDiskSpaceUsed(
                    organisation.assignedResellerFileSize,
                    organisation.maxResellerFileSize
                  )
                "
                class="ml-2"
              >
                <v-progress-circular
                  :rotate="90"
                  :size="24"
                  :color="
                    calculateColourDiskSpaceUsed(
                      organisation.totalResellerFileSize,
                      organisation.maxResellerFileSize
                    )
                  "
                  :value="
                    calculatePcDiskSpaceUsed(
                      organisation.totalResellerFileSize,
                      organisation.maxResellerFileSize
                    )
                  "
                >
                  <span style="font-size: 60%">{{
                    calculatePcDiskSpaceUsed(
                      organisation.assignedResellerFileSize,
                      organisation.maxResellerFileSize
                    )
                  }}</span>
                </v-progress-circular>
              </v-progress-circular>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">
                {{ $t("organisations.customLoginUrl") }}:
              </div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ customLoginUrl }}</div>
            </v-col>
          </v-row>
        </v-container>

        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <v-btn
            v-if="
              authCanByOrganisationId(
                'editFromParent.organisations',
                organisation.parentOrganisationId
              )
            "
            v-bind="propsButtonAction"
            :to="{
              name: 'organisations-edit-from-parent',
              params: { id: objectId },
            }"
            data-cy="button-edit"
            >{{ $t("common.edit") }}</v-btn
          >
          <v-btn
            v-if="authCanByOrganisationId('editOwn.organisations', objectId)"
            v-bind="propsButtonAction"
            :to="{ name: 'organisations-edit', params: { id: objectId } }"
            data-cy="button-edit"
            >{{ $t("common.edit") }}</v-btn
          >
        </div>
      </template>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import MiscUtils from "@/modules/base/miscUtils.mixin";
import OrganisationLevelFieldRO from "@/modules/organisations/bits/OrganisationLevelFieldRO";
import CountryPickerFieldRO from "@/modules/base/bits/CountryPickerFieldRO";
import LanguagePickerFieldRO from "@/modules/base/bits/LanguagePickerFieldRO";

export default {
  name: "OrganisationsShow",
  mixins: [Permissions, ComponentStatus, MiscUtils],
  components: {
    OrganisationLevelFieldRO,
    CountryPickerFieldRO,
    LanguagePickerFieldRO,
  },
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
  }),
  computed: {
    logoUrl() {
      return (id) => {
        return this.$store.getters["organisations/getLogoUrl"](id);
      };
    },
    customLoginUrl() {
      let customLoginUrl = "";
      let organisationIdToken =
        this.$store.getters["auth/calcOrganisationIdToken"];

      if (organisationIdToken) {
        customLoginUrl =
          location.protocol + "//" + location.host + "/login?oi=";
        customLoginUrl += encodeURIComponent(organisationIdToken);
      }
      return customLoginUrl;
    },
    // 2) When the organisation id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the organisation data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    organisation() {
      return this.$store.getters["organisations/readById"](this.objectId);
    },
    loggedInOrganisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
  },
  watch: {
    // 3) If the organisation id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        this.setStatusReading();
        this.$store
          .dispatch("organisations/readById", this.objectId)
          .then((/* result */) => {
            this.setStatusReadSuccess();
          })
          .catch((/* error */) => {
            this.setStatusReadError();
            this.$store.commit("status/showError");
          });
      },
    },
  },
  created() {},
  methods: {
    isReseller(organisation) {
      return organisation.level === APP_CONSTANTS.ORGANISATION_LEVELS_RESELLER;
    },
    hasImpersonationsFromOrganisation(organisationId) {
      return this.$store.getters["auth/hasImpersonationsFromOrganisation"](
        organisationId
      );
    },
    clickImpersonate(id) {
      let impersonations =
        this.$store.getters["auth/getImpersonationsFromById"](id);
      // TODO: handle when there is more than one possible case.
      this.$store
        .dispatch("auth/impersonateTo", {
          to_user_id: impersonations[0]["to_user_id"],
          to_organisation_id: impersonations[0]["to_organisation_id"],
        })
        .then((/* result */) => {
          this.setStatusReadSuccess();
          if (
            this.$route.name === "home" ||
            this.$route.name === "documents-index"
          ) {
            this.$router.go();
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch((/* error */) => {
          this.setStatusReadError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
