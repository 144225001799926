<template>
  <span data-cy="select-country-ro">{{ country }}</span>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";

export default {
  name: "CountryPickerFieldRO",
  props: {
    countryValue: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    country() {
      let country = APP_CONSTANTS.COUNTRIES.find((item) => {
        return item.value === this.countryValue;
      });
      if (country) {
        return this.$t(`countries.${country.name}`);
      } else {
        return "";
      }
    },
  },
};
</script>
