<template>
  <span data-cy="select-language-ro">{{ language }}</span>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";

export default {
  name: "LanguagePickerFieldRO",
  props: {
    languageValue: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    language() {
      let language = APP_CONSTANTS.LANGUAGES.find((item) => {
        return item.value === this.languageValue;
      });
      if (language) {
        return this.$t(`languages.${language.name}`);
      } else {
        return "";
      }
    },
  },
};
</script>
